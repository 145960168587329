import React, { useState, useEffect } from 'react';
import './Header.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import translateText from '../utils/translate'; // Keep translateText for cloud function translation

const Header = ({ setLanguage }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [categories, setCategories] = useState([]);
  const [showCoursesDropdown, setShowCoursesDropdown] = useState(false);
  const [showAvatarDropdown, setShowAvatarDropdown] = useState(false);
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState('');
  const [profileImage, setProfileImage] = useState('/images/default_avatar.png');
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [translations, setTranslations] = useState(null); // Initialize to null

  // Fetch translations from Firestore based on the selected language
  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const docRef = doc(db, 'translations', 'Header');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const translationData = JSON.parse(data[selectedLanguage]); // Parse the string to JSON
          setTranslations(translationData); // Update state with the parsed data
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchTranslations();
  }, [selectedLanguage]);

  // Fetch categories and translate them using the cloud function
  useEffect(() => {
    const fetchAndTranslateCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'categories'));
        const categoriesList = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const name = await translateText(doc.data().name, selectedLanguage); // Translate category names
            return {
              name,
              uid: doc.id,
            };
          })
        );
        setCategories(categoriesList);
        sessionStorage.setItem('categories', JSON.stringify(categoriesList));
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchAndTranslateCategories();
  }, [selectedLanguage]);

  useEffect(() => {
    const storedName = localStorage.getItem('userName');
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
      setLanguage(storedLanguage);
    }

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        if (storedName) {
          setUserName(storedName);
        }

        const userDoc = await getDoc(doc(db, 'students', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserName(userData.name || storedName);
          if (userData.ProfilePicture) {
            setProfileImage(userData.ProfilePicture);
          }
        }
      } else {
        setUser(null);
        setProfileImage('/images/default_avatar.png');
      }
    });

    return () => unsubscribe();
  }, [location.state, setLanguage]);

  const handleCategoryMouseEnter = () => {
    setShowCoursesDropdown(true);
  };

  const handleCategoryMouseLeave = () => {
    setShowCoursesDropdown(false);
  };

  const handleAvatarMouseEnter = () => {
    setShowAvatarDropdown(true);
  };

  const handleAvatarMouseLeave = () => {
    setShowAvatarDropdown(false);
  };

  const handleCategoryClick = (category) => {
    navigate(`/courses?category=${category.uid}`);
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      setUser(null);
      localStorage.removeItem('userName');
      navigate('/');
    });
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
    setLanguage(newLanguage);
    localStorage.setItem('selectedLanguage', newLanguage);
  };

  return (
    <header>
      <div className="status-bar">
        <Container>
          <Row>
            <Col className="text-center text-md-start">
              <div className="language-selector-wrapper">
                {translations && (
                  <>
                    <span className="language-selector-label">{translations.changeLanguage}:&nbsp;</span>
                    <select
                      className="language-selector"
                      value={selectedLanguage}
                      onChange={handleLanguageChange}
                    >
                      <option value="en">English</option>
                      <option value="de">Deutsch</option>
                    </select>
                  </>
                )}
              </div>
            </Col>
            <Col className="text-center text-md-end">
              +41 79 660 51 51&nbsp;|&nbsp;info@oasee.ch
            </Col>
          </Row>
        </Container>
      </div>
      <div className="header-main">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={4}>
              <nav className="menu">
                {translations && (
                  <>
                    <Link to="/">{translations.home}</Link>
                    <div
                      className="dropdown-wrapper"
                      onMouseEnter={handleCategoryMouseEnter}
                      onMouseLeave={handleCategoryMouseLeave}
                    >
                      <span className="dropdown-label">{translations.courses}</span>
                      <div className={`dropdown-menu ${showCoursesDropdown ? 'show' : ''}`}>
                        {categories.map((category) => (
                          <div
                            className="dropdown-item"
                            key={category.uid}
                            onClick={() => handleCategoryClick(category)}
                          >
                            {category.name}
                          </div>
                        ))}
                      </div>
                    </div>
                    <a href="/booking">{translations.booking}</a>
                    <Link to="/about">{translations.about}</Link>
                    <a href="/contact">{translations.contact}</a>
                  </>
                )}
              </nav>
            </Col>
            <Col xs={12} md={4} className="text-center">
              <div className="logo">
                <img src="/images/logo_1000ppi.png" alt="Logo"/>
              </div>
            </Col>
            <Col xs={12} md={4} className="text-center text-md-end">
              {user ? (
                <div
                  className="avatar-wrapper"
                  onMouseEnter={handleAvatarMouseEnter}
                  onMouseLeave={handleAvatarMouseLeave}
                >
                  {translations && (
                    <>
                      <span className="welcome-text">{translations.welcome}, {userName}</span>
                      <img
                        src={profileImage}
                        alt="Profile"
                        className="avatar"
                        height="30px"
                      />
                      {showAvatarDropdown && (
                        <div className="custom-dropdown">
                          <div className="user-info">
                            <strong>{userName}</strong>
                            <br />
                            <small>{user.email}</small>
                          </div>
                          <hr />
                          <ul className="dropdown-links">
                            <li><Link to="/my-sessions">{translations.mySessions}</Link></li>
                            <li><Link to="/my-courses">{translations.myCourses}</Link></li>
                            <li><Link to="/wishlist">{translations.wishlist}</Link></li>
                            <li><Link to="/profile-settings">{translations.profileSettings}</Link></li>
                            <li onClick={handleLogout}>{translations.logout}</li>
                          </ul>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ) : (
                translations && (
                  <>
                    <Link to="/login">
                      <Button variant="outline-primary" className="me-2">{translations.signIn}</Button>
                    </Link>
                    <Link to="/register">
                      <Button variant="primary">{translations.register}</Button>
                    </Link>
                  </>
                )
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </header>
  );
};

export default Header;
